.main-id-container{
    width: 8cm;
  }
  .main-id-container h5{
    text-align: center;
    margin: 5px 1px;
  }
  .card1 {
    border: 1px solid #919EAB52;
    width: 86mm;
    height: 100mm;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF url('../../assets/images/g73.png') center center / contain no-repeat;
    background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../../assets/images/g73.png');
    background-size: contain;
    max-height: 100mm;
    max-width: 86mm;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  
  
  .pd-block{
    padding-inline: 4px;
  }
  .header img {
    width: 100%;
    height: auto;
  
  }
  .ph-qr{
    display: flex;
    justify-content: center;
    gap:5px;
  }
  .ph-qr img{
    max-width: 140px;
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
    display: block; 
  }
  .details{
    display: flex;
    gap:1px;
  }
  
  .details h4{
   margin: 1px;
   font-weight: bold;
   font-size: 15px; 
   font-family: 'Arial', sans-serif;
   color: #333333; 
  }
  .details > :first-child {
  flex: 1.2
  }
  
  /* Style for the second child */
  .details> :nth-child(2) {
    flex:3;
  }
  .bd{
    border-top: 1px solid #919EAB52; 
    background-color: #EE7F1C;
    margin: 0px !important;
    color: azure;
    font-size: 35px;
  }

  .img-header{
    padding-inline: 8px;
  } 
 